import style from "./ExchangeDesclaimer.module.scss";
// import { AiFillStar } from "react-icons/ai";

function ExchangeDesclaimer({ heading }) {
	return (
		<div className={style.container}>
			<h2>{heading}</h2>
			<div className={style.exchange__desclaimer}>
				{/* -- 1 -- */}
				{/* <div className={style.desc}>
					<div className={style.descNumb}>
						<div>
							<span>
								<AiFillStar /> <AiFillStar />
							</span>
						</div>
					</div>
					<div className={style.descText2}>বিসমিল্লাহির রাহমানির রাহিম </div>
					<div className={style.descNumb}>
						<div>
							<span>
								<AiFillStar /> <AiFillStar />
							</span>
						</div>
					</div>
				</div> */}
				{/* -- 1 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>1</div>
					</div>
					<div className={style.descText}>
						আস-সালামু আলাইকুম। Welcome to Dollar Buy Sell Corner.
					</div>
				</div>
				{/* -- 2 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>2</div>
					</div>
					<div className={style.descText}>
					আমরা সকাল 10 টা থেকে রাত 11 টা পর্যন্ত অর্ডার Complete করে থাকি।
						Order করার পরে অবশ্যই 5 - 60 মিনিট অপেক্ষা করুন.
					</div>
				</div>
				{/* -- 7 -- */}
				{/* <div className={style.desc}>
					<div className={style.descNumb}>
						<div>3</div>
					</div>
					<div className={style.descText}>
					কিছু কারন বশত এই ওয়েবসাইট টি লোড নিতে প্রব্লেম করতে পারে,  সেক্ষেত্রে   <br/>
					 <a href="https://dollarbuysellbdcorner.com">dollarbuysellbdcorner.com</a> <br/>ওয়েবসাইট টি  ব্যবহার করতে পারেন।

					</div>
				</div> */}
				{/* -- 7 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>3</div>
					</div>
					<div className={style.descText}>
						100+ binance এর বেশি ক্রয় করলে ক্রয়মূল্য কিছুটা কমে যাবে তবে সেক্ষেত্রে Whatsapp এ যোগাযোগ করতে হবে । আর যারা প্রতিদিন 100+ binance নিবে তারা আরও কিছু সুবিধা পেয়ে থাকবে Rate এর বিষয়ে ।

					</div>
				</div>
				{/* -- 6 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>4</div>
					</div>
					<div className={style.descText}>
						
						
						 whatsapp_<a
							href=" https://wa.me/17167557413"
							target="_blank"
							rel="noreferrer"
						>
							+17167557413 
						</a> / <a href="https://dollarbuysellcorner.com">dollarbuysellcorner.com</a> / <br/>
						<a href="https://dollarbuysellbdcorner.com">dollarbuysellbdcorner.com</a><br/>
						ছাড়া অন্য কোন মাধ্যমে লেনদেন করে প্রতারিত
						হলে তার দায়ভার Dollar buy sell Corner গ্রহন করবে না।
					</div>
				</div>
				{/* -- 7 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>5</div>
					</div>
					<div className={style.descText}>
						NID Verification এর ক্ষেত্রে ভুল তথ্য প্রদান থেকে বিরত থাকুন অন্যথায় Account বাতিল করে দেয়া হবে ।
					</div>
				</div>
				{/* -- 7 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>6</div>
					</div>
					<div className={style.descText}>
						অপরাধ এবং প্রতারণা মূলক কর্মকান্ড বেড়ে যাওয়ার কারণে আমাদের এবং
						কাস্টমার এর সকল তথ্য গোপন করা হয়েছে ।
					</div>
				</div>
				{/* -- 4 --
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>5</div>
					</div>
					<div className={style.descText}></div>
					যে কোন Transactions এর service চার্জ আমরা বহন করব না। Transaction Fee
					এর Prove হিসেবে Screenshort দেয়া যাবে।
				</div> */}

				{/* -- 3 --
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>6</div>
					</div>
					<div className={style.descText}>
						Skrill ক্রয় করার সর্বনিম্ন অর্ডার 10 ডলার, Pyypl এর সর্বনিম্ন 5
						ডলার, USDT এর সর্বনিম্ন 10 ডলার।
					</div>
				</div> */}
				{/* -- 3 --
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>7</div>
					</div>
					<div className={style.descText}>
						কাস্টমার কে skrill এর জন্য অবশ্যই 10+0.5 $ / 15+0.5 $ ( Amount + fee
						), Pyypl এর জন্য 5+0.3 $ / 10+0.3 $ ( Amount + fee ), USDT এর জন্য
						10+1 / 15+1 $ ( Amount + fee ) অর্ডার করতে হবে তা না হলে অর্ডারটি
						cancel হয়ে যাবে।
					</div>
				</div> */}
				{/* -- 5 --
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>5</div>
					</div>
					<div className={style.descText}>
						নামাজ বিরতি 30 মিনিট । Dollar buy sell সার্ভিস নিয়ে কোন প্রকার
						মন্তব্য থাকলে আমাদের{" "}
						<a
							href="https://www.facebook.com/Exchangecornerbd"
							target="_blank"
							rel="noreferrer"
						>
							Facebook page
						</a>{" "}
						এ জানাতে পারেন।
					</div>
				</div> */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>7</div>
					</div>
					<div className={style.descText}>
						আমরা শুধুমাত্র ফ্রিল্যান্সার, আউটসোর্সার এবং অনলাইন কর্মীদের জন্য
						কাজ করি যাদের অনলাইন পেমেন্ট সমাধান প্রয়োজন।
					</div>
				</div>
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>8</div>
					</div>
					<div className={style.descText}>
						আমরা কোন রকম বেটিং, জুয়া এবং অবৈধ ওয়েব পেমেন্ট সমর্থন করি না।
					</div>
				</div>
				{/* -- 4 -- */}
				<div className={style.desc}>
					<div className={style.descNumb}>
						<div>9</div>
					</div>
					<div className={style.descText}></div>
					নিজ নাম্বার ছাড়া টাকা পাঠাবেন না, তা না হলে অর্ডারটি cancel হয়ে যাবে।
					‍আলাদা নাম্বার দিয়ে টাকা পাঠালে এবং আমরা ফোন দিয়ে আমাদের কোন রকম অপরাধ
					বা প্রতারণা মূলক কর্মকান্ড সন্দেহ হলে তার সকল তথ্য প্রশাসন এর নিকট
					হস্তান্তর করা হবে
				</div>

				{/* -- extra -- */}
				<div className={style.extra}>
					<p>
						আমরা দেশের সকল আইন এর প্রতি শ্রদ্ধাশীল। কোন রকম অপরাধ বা প্রতারণা
						মূলক কর্মকান্ডের জন্য আমাদের সার্ভিস ব্যবহার করলে{" "}
						<span>Dollar buy sell Corner</span> কর্তৃপক্ষ কোনোভাবেই দায়ী থাকবে
						না ।
					</p>
				</div>
			</div>
		</div>
	);
}

export default ExchangeDesclaimer;
